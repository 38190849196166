
import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import Content 		from './Content';
import Portofolio 	from '../../../components/Portofolio';
import Interested 	from '../../../components/Interested';
import { bigTv as mediaQuery, normalScreen, bigScreen } from '../../../components/MediaQueries'
import '../../../assets/client.css';

import  React from 'react'
import { ClientPage, LineBreak, AboutUs, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice()

function Client() {

	//Google Analitik
	ReactGA.event({
		category: "user",
		action: "Company Client"
	})

	const [result, setResult] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	let [tertarik, setTertarik] = React.useState(null)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {

		CreateToken().then((dataToken) => {
			ClientPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
	
			AboutUs().then((data) => {
				setTertarik(data.response[0])
			}).catch(err => {
				console.log(err)
			})
		})
	
	}, [langCondition]);

	let eBanner_title 		= <h1 className={(mediaQuery) ? "banner-text fw-bold container max-width-1100px w-100" : "banner-text fw-bold"}><span>{parseInt(sessionStorage.getItem('lang')) === 1 ? "Clients" : "Klien"}</span></h1>;
	let eTitle 				= parseInt(sessionStorage.getItem('lang')) === 1 ? "Clients" : "Klien"
	let sBanner_desc  		= <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{ (result !== null) ? LineBreak(result.tentang_1) : <Shimmer width={"20rem"} height={"4rem"} />}</div>;
	let sAfterBanner_title 	= (result !== null) ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />
	let sAfterBanner_desc	= <span className="fs-20">{ (result !== null) ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} />}</span>;
	
	let Portofolio_sub 		= "Business",
		Portofolio_title	= parseInt(sessionStorage.getItem('lang')) === 1 ? "Our Valued Customers" : "Our Valued Customers",
		Portofolio_desc		= (result !== null) ? LineBreak(result.clients) : <Shimmer width={"20rem"} height={"4rem"} />

	let mainBannerPage		= isCompatible ? "https://minio.nexa.net.id/nexa/client.webp" : "https://minio.nexa.net.id/nexa/client.jpeg"

	let mediaCondition;
	if (normalScreen){
		mediaCondition = {"top":"0px", "height":"200px"}
	} else if (bigScreen) {
		mediaCondition = {"top":"180px"}
	} else {
		mediaCondition = {"top":"-80px", "height":"200px"}
	}
	
	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>

			<Banner title={eBanner_title} desc={sBanner_desc} url_image={mainBannerPage} style={(mediaQuery) ? {"maxWidth":"unset"} : {}} />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} styleSection={mediaCondition} />
			<Content />
			<Portofolio sub_title={Portofolio_sub} title={Portofolio_title} desc={Portofolio_desc}/>
			{/* { (result == null) && <Interested storeData={ null} /> }
			{ (result !== null) && <Interested storeData={result} /> } */}
			<Interested storeData = {tertarik}/>
			
		</div>
  );
}

export default Client;
