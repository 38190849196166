import Content from "./Content";
import Banner from "../../../components/Banner";
import { bigTv as mediaQuery } from "../../../components/MediaQueries";

import React from "react";
import {
  NexatelPage,
  NexaappsPage,
  NexadataPage,
  NexadigitalPage,
  NexasolutionPage,
  getArticle,
  LineBreak,
  setImgDownload,
  getLinkDownloadIos,
  getLinkDownloadAndroid,
  CreateToken,
  homeHelper,
} from "../../../controller/index";

import ReactGA from "react-ga";
import { useSelector } from "react-redux";

import { Helmet } from "react-helmet";

function Home({ storeData }) {
  //Google Analitik

  ReactGA.event({ category: "user", action: "Company Home" });

  const langNexaLevel = useSelector((state) => state.homePage);
  const mainServices = useSelector((state) => state.mainServices);
  const [tel, setTel] = React.useState(null);
  const [app, setApp] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [digital, setDigital] = React.useState(null);
  const [solu, setSolu] = React.useState(null);
  const [article, setArticle] = React.useState(null);

  const langs = useSelector((state) => state.langs);
  const [statusLang] = React.useState(langs[0].idLang);
  let [img, setImg] = React.useState(null);
  let [downloadIOS, setLinkIOS] = React.useState(null);
  let [downloadAndroid, setLinkAndroid] = React.useState(null);
  let [homeLang, setHomeLang] = React.useState(null);

  let langCondition = statusLang !== langs[0].idLang;

  React.useEffect(() => {
    CreateToken().then((dataToken) => {
      NexatelPage()
        .then((data) => {
          setTel(data.response[0].tentang_1.split("|")[1]);
        })
        .catch((err) => {
          console.log("setTel", err);
        });

      NexaappsPage()
        .then((data) => {
          setApp(data.response[0].tentang_1);
        })
        .catch((err) => {
          console.log("setApp", err);
        });
      NexadataPage()
        .then((data) => {
          setData(data.response[0].tentang_1);
        })
        .catch((err) => {
          console.log("setData", err);
        });
      NexadigitalPage()
        .then((data) => {
          setDigital(data.response[0].tentang_1);
        })
        .catch((err) => {
          console.log("setDigital", err);
        });
      NexasolutionPage()
        .then((data) => {
          setSolu(data.response[0].tentang_1);
        })
        .catch((err) => {
          console.log("setSolu", err);
        });
      getArticle()
        .then((data) => {
          // console.log(data)
          let res_data = data;
          if (res_data.metadata.status !== 200) {
            console.log("Error ", res_data.message);
          } else {
            setArticle(res_data.response.news_articles);
          }
        })
        .catch((err) => {
          console.log("setTel", err);
        });

      setImg(setImgDownload());
      // console.log(setImgDownload())

      getLinkDownloadIos()
        .then((data) => {
          setLinkIOS(data.response[0].link);
        })
        .catch((err) => {
          console.log(err);
        });

      getLinkDownloadAndroid()
        .then((data) => {
          setLinkAndroid(data.response[0].link);
        })
        .catch((err) => {
          console.log(err);
        });

      homeHelper()
        .then((data) => {
          setHomeLang(data);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  }, [langCondition]);

  // console.log('Solution', solu);
  // console.log('statusLang', statusLang);
  // console.log('langs[0].idLang', langs[0].idLang);

  let arrDataBanner = [
    {
      title: (
        <h1
          className={
            mediaQuery
              ? "banner-text fw-bold container max-width-1100px w-100"
              : "banner-text fw-bold row"
          }
        >
          <span>Next Level</span>
          <br />
          <span className="solutioncorporate-text002">Experience</span>
        </h1>
      ),
      desc: (
        <div className={mediaQuery ? "container max-width-1100px" : ""}>
          {LineBreak(langNexaLevel[0].desc)}
        </div>
      ),
      url_image: "./assets/images/banner/nexahome.png",
    },
    // {
    //   'title': <h1 className={(mediaQuery) ? "banner-text fw-bold container max-width-1100px w-100" : "banner-text fw-bold"}><span>Nexatel</span></h1>,
    //   'desc': <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{ tel !== null ? LineBreak(tel) : 'fetch loading...'}</div>,
    //   'url_image': './assets/images/about/banner_about.jpeg'
    // },
    // {
    //   'title': <h1 className={(mediaQuery ? "banner-text fw-bold text-danger container max-width-1100px w-100" : "banner-text fw-bold text-danger")}><span>Nexa Apps</span></h1>,
    //   'desc': <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{ app !== null ? LineBreak(app) : 'fetch loading...'}</div>,
    //   'url_image': './assets/images/banner/app.jpeg'
    // },
    // {
    //   'title': <h1 className={(mediaQuery) ? "banner-text fw-bold text-primary container max-width-1100px w-100" : "banner-text fw-bold text-primary"}><span>Nexa Data</span></h1>,
    //   'desc': <div className={(mediaQuery) ? "container max-width-1100px" : ""} >{ data !== null ? LineBreak(data) : 'fetch loading...'}</div>,
    //   'url_image': './assets/images/banner/nexadata.jpeg'
    // },
    // {
    //   'title': <h1 className={(mediaQuery) ? "banner-text fw-bold text-pink container max-width-1100px w-100" : "banner-text fw-bold text-pink"}><span>Nexa Digital</span></h1>,
    //   'desc': <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{ digital !== null ? LineBreak(digital) : 'fetch loading...'}</div>,
    //   'url_image': './assets/images/banner/digital.jpeg'
    // },
    // {
    //   'title': <h1 className={(mediaQuery) ? "banner-text fw-bold text-primary container max-width-1100px w-100" : "banner-text fw-bold text-primary"} ><span>Nexa Solution</span></h1>,
    //   'desc': <div className={(mediaQuery) ? "container max-width-1100px" : ""} >{ solu !== null ? LineBreak(solu) : 'fetch loading...'}</div>,
    //   'url_image': './assets/images/banner/nexasolution.jpeg'
    // }
  ];
  let eTitleHeader = (
    <h1 className="solutioncorporate-text row" style={{ paddingLeft: "5%" }}>
      <span>Next Level</span>
      <br />
      <span className="solutioncorporate-text002">Experience</span>
    </h1>
  );
  let eStyle = { width: "100%", gridGap: "unset", "padding-left": "5%" };

  return (
    <div>
      {/* Dynamic Meta Change Every Page*/}
      <Helmet>
        <title>Nexa - Nexa Level Experience</title>
        <meta property="og:title" content="Nexa - Nexa Level Experience"></meta>
        <meta property="og:site_name" content="Nexa"></meta>
        <meta property="og:url" content="https://nexa.net.id/"></meta>
        <meta
          property="og:description"
          content="Penyedia Layanan Digital Terpercaya di Indonesia. Mengembangkan bisnis Anda, memberikan solusi yang sesuai dan menghadirkan layanan untuk bisnis Anda dengan teknologi generasi terbaru."
        ></meta>
        <meta
          name="description"
          content="Penyedia Layanan Digital Terpercaya di Indonesia. Mengembangkan bisnis Anda, memberikan solusi yang sesuai dan menghadirkan layanan untuk bisnis Anda dengan teknologi generasi terbaru."
        ></meta>
        <meta property="og:type" content="product"></meta>
        <meta
          property="og:image"
          content="https://minio.nexa.net.id/nexa/nexa-homepage-background-1500w.webp"
        ></meta>
      </Helmet>

      <Banner
        slider={"singleWeb"}
        title={eTitleHeader}
        desc={
          homeLang !== null
            ? homeLang.bannerDesc
            : "Kami tidak hanya penyedia tetapi juga teman dalam semua masalah"
        }
        arrayData={arrDataBanner}
        style={mediaQuery ? { maxWidth: "unset" } : eStyle}
        styleText={eStyle}
      />
      <Content
        storeData={mainServices}
        news={article}
        img_download={img}
        link_ios={downloadIOS}
        link_android={downloadAndroid}
        homeLangData={homeLang}
      />
    </div>
  );
}

export default Home;
