import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import Content 		from './Content';
import Interested 	from '../../../components/Interested';
import { bigTv as mediaQuery, normalScreen, bigScreen } from '../../../components/MediaQueries'
import '../../../assets/companyabout.css';
import { AboutUs, CreateToken, detectDevice } from '../../../controller/index'
import  React from 'react'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';

let isCompatible = detectDevice()

function Company(props) {
	
	//Google Analitik
	ReactGA.event({
		category: "user",
		action: "About Company"
	})

	const [result, setResult] = React.useState(null)

	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	let langCondition = (statusLang !== langs[0].idLang)
	
	React.useEffect(() => {
		CreateToken().then((dataToken) => {
			AboutUs().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
	}, [langCondition]);

	let i = 0;
	// console.log('result '+i++,typeof result == 'undefined', result);

	let eBanner 			= <h1 className={(mediaQuery) ? "solutioncorporate-text container max-width-1100px" : "solutioncorporate-text row"}><span>{(result !== null) ? result.tentang_1.split('|')[0] : <Shimmer width={"20rem"} height={"4rem"} />}</span></h1>;;
	let eTitle 				= (result !== null) ? result.tentang_1.split('|')[0].trim().replace(/^\s+|\s+$/g, '') : "Tentang Kami"
	let sBanner_desc 		= <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{(result !== null) ? result.tentang_1.split('|')[1] : <Shimmer width={"20rem"} height={"4rem"} />}</div>
	let sAfterBanner_title 	= (result !== null) ? result.tentang_2 : <Shimmer width={"20rem"} height={"4rem"} />;
	let sAfterBanner_desc 	= (result !== null) ? result.tentang_3 : <Shimmer width={"20rem"} height={"4rem"} />;
	let mainBannerPage		= isCompatible ? 'https://minio.nexa.net.id/nexa/banner_about.webp' : "https://minio.nexa.net.id/nexa/banner_about.jpeg";

	let mediaCondition;
	if (normalScreen){
		mediaCondition = {"top":"0px", "height":"75px"}
	} else if (bigScreen) {
		mediaCondition = {"top":"180px"}
	} else {
		mediaCondition = {"top":"-80px", "height":"75px"}
	}

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>
			
			<Banner slider={false} title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={(mediaQuery) ? {"maxWidth":"unset"} : {}} />
			{/* <Banner slider={false} title={eBanner} url_image={mainBannerPage} style={(mediaQuery) ? {"maxWidth":"unset"} : {}} /> */}
			<AfterBanner title=	{sAfterBanner_title} desc={sAfterBanner_desc} styleSection={mediaCondition} />
			<Content storeData={(result !== null) && result } />
			{ (result == null) && <Interested storeData={null} /> }
			{ (result !== null) && <Interested storeData={result} /> }
			
		</div>
	);
}


export default Company;
