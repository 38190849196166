import Header 		from '../../../components/Header';
import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import Content 		from './Content';
import Footer 		from '../../../components/Footer';
import Interested from '../../../components/Interested';
import Interested_min from '../../../components/Interested_min';

import { bigTv as mediaQuery, normalScreen, bigScreen } from '../../../components/MediaQueries'
import '../../../assets/nexaapp.css';

import  React from 'react'
import { NexaappsPage, LineBreak, CreateToken, detectDevice, AboutUs } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import {Helmet} from 'react-helmet';

let isCompatible = detectDevice();

function Nexaapps() {

	//Google Analitik
	ReactGA.event({category:"user", action:"Service NexaApp"})
	
	const [result, setResult] = React.useState(null)
	const [interested, setInterested] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {

		CreateToken().then((data) => {

			AboutUs().then((data) => {
				setInterested(data.response[0])
			})

			NexaappsPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
		})
		
	}, [langCondition]);

	let eBanner 			= <h1 className={(mediaQuery) ? "banner-text fw-bold text-danger container max-width-1100px w-100" : "banner-text fw-bold text-danger"}><span>Nexa Apps</span></h1>;
	let eTitle				= "Nexa App"
	let sBanner_desc  		= <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{ result !== null ? LineBreak(result.tentang_1) : <Shimmer width={"20rem"} height={"4rem"} />}</div>;
	let eStyleBanner = {"width": "100%"}
	let eStyleBannerText = {"width": "100%"}

	let sAfterBanner_title 	= result !== null ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />;
	let sAfterBanner_desc 	= result !== null ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} /> 
	let desc				= result !== null ? result.tentang_3 : ""
	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_app.webp" : "https://minio.nexa.net.id/nexa/banner_app.jpeg"

	let mediaCondition;
	if (normalScreen){
		mediaCondition = {"top":"0px", "height":"200px"}
	} else if (bigScreen) {
		mediaCondition = {"top":"180px", "height":"400px"}
	} else {
		// mediaCondition = {"top":"-80px", "height":"200px"}
		mediaCondition = {"top":"0px", "height":"200px"}
	}

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={desc}></meta>
				<meta name="description" content={desc}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>
			<Banner title={eBanner} desc={sBanner_desc} style={(mediaQuery) ? {"maxWidth":"unset"} : eStyleBanner} styleText={eStyleBannerText} url_image={mainBannerPage} />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} styleSection={mediaCondition} />
			{ result === null && <Content storeData={null} />}
			{ result !== null && <Content storeData={{'features':result.services_features, 'product': result.product_services, 'interested_min': interested} } />}
			{ (interested == null) && <Interested storeData={null} /> }
			{ (interested !== null) && <Interested storeData={interested} /> }
	
		</div>
		);
}

export default Nexaapps;