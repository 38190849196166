
import Banner 		from '../../../components/Banner';
import AfterBanner 	from '../../../components/AfterBanner';
import Content 		from './Content';
import { bigTv as mediaQuery, normalScreen, bigScreen } from '../../../components/MediaQueries'
import '../../../assets/nexatel.css';
import Interested 	from '../../../components/Interested';

import React from 'react'
import { NexatelPage, LineBreak, CreateToken, detectDevice, AboutUs } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';


let isCompatible = detectDevice()

function Nexatel() {

	//Google Analitik
	ReactGA.event({category:"user", action:"Service NexaTel"})

	const [result, setResult] = React.useState(null)
	const [interested, setInterested] = React.useState(null)

	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)

	let langCondition = (statusLang !== langs[0].idLang)

	React.useEffect(() => {

		CreateToken().then((data) => {
			
			AboutUs().then((data) => {
				setInterested(data.response[0]) 
			})

			NexatelPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
			
		})
	}, [langCondition]);

	let eBanner 			= <h1 className={(mediaQuery) ? "banner-text fw-bold container max-width-1100px w-100" : "banner-text fw-bold"}><span>Nexatel</span></h1>;
	let eTitle				= "Nexatel"
	let sBanner_desc  		= <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{(result !== null) ? LineBreak(result.tentang_1.split('|')[1]) : <Shimmer width={"20rem"} height={"4rem"} />}</div>;
	let sAfterBanner_title 	= <span className="fs-36">{(result !== null) ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />}</span>;
	let sAfterBanner_desc 	= <span className="fs-22">{(result !== null) ? LineBreak(result.tentang_3) : <Shimmer width={"20rem"} height={"4rem"} />}</span>;
	let mainBannerPage		= isCompatible ? "https://minio.nexa.net.id/nexa/banner_nexatel.webp" : "https://minio.nexa.net.id/nexa/banner_nexatel.jpeg"
	
	let mediaCondition;
	if (normalScreen){
		mediaCondition = {"top":"0px", "height":"200px"}
	} else if (bigScreen) {
		mediaCondition = {"top":"180px"}
	} else {
		mediaCondition = {"height":"200px"}
	}

	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>
			
			<Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={(mediaQuery) ? {"maxWidth":"unset"} : {} } />
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} styleSection={mediaCondition} />
			{result == null && <Content storeData={null} /> }
			{result !== null && <Content storeData={result} interested_min={interested} /> }
			{ (interested == null) && <Interested storeData={null} /> }
			{ (interested !== null) && <Interested storeData={interested} /> }
			
		</div>
		);
}

export default Nexatel;