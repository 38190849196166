
import Banner from '../../../components/Banner';
import AfterBanner from '../../../components/AfterBanner';
import Content from './Content';
import { bigTv as mediaQuery, normalScreen, bigScreen } from '../../../components/MediaQueries'
import '../../../assets/career.css';

import  React from 'react'
import { CareerPage, DetailCareer, LineBreak, ListCareer, CreateToken, detectDevice } from '../../../controller/index'
import { useSelector } from 'react-redux'
import ReactGA from 'react-ga'
import { Shimmer } from 'react-shimmer';
import { Helmet } from 'react-helmet';


let isCompatible = detectDevice()

function Career() {

	//Google Analitik
	ReactGA.event({
		category: "user",
		action: "Company Career"
	})

	const [result, setResult] = React.useState(null)
	const [objCareer, setListCareer] = React.useState(null)
	const langs = useSelector(state => state.langs);
	const [statusLang] = React.useState(langs[0].idLang)
	let [careerText, setCareerText] = React.useState(null)

	let langCondition = (statusLang !== langs[0].idLang)

	let careerTextPage = () => {
		let lang = sessionStorage.getItem('lang')
		let data
		if(parseInt(lang) === 2	){
			data =  {
				"branchTitle":"Cabang Kami"
			}
		}else{
			data = {
				"branchTitle":"Our Branch"
			}
		}

		return data
	}


	React.useEffect(() => {

		CreateToken().then((dataToken) => {
			CareerPage().then((data) => {
				setResult(data.response[0]) 
			}).catch((err) => {
				setResult(err)
			})
	
			ListCareer().then((data) => {
				// console.log(data.response)
				setListCareer(data.response)
			}).catch((err) => {
				setListCareer(err)
			})
			
			setCareerText(careerTextPage())
		})
	}, [langCondition]);

	

	let eBanner 			= <h1 className={(mediaQuery) ? "banner-text fw-bold container max-width-1100px w-100" : "banner-text fw-bold"}><span>Career</span></h1>;
	let eTitle				= "Career"
	let sBanner_desc  		= <div className={(mediaQuery) ? "container max-width-1100px" : ""}>{ (result !== null) ? LineBreak(result.tentang_1) :<Shimmer width={"20rem"} height={"4rem"} /> }</div>;
	let sAfterBanner_title 	= (result !== null) ? LineBreak(result.tentang_2) : <Shimmer width={"20rem"} height={"4rem"} />;
	let sAfterBanner_desc 	= <span className="fs-20">{ (result !== null) ? LineBreak(result.tentang_3) :<Shimmer width={"20rem"} height={"4rem"} /> }</span>;
	let mainBannerPage 		= isCompatible ? "https://minio.nexa.net.id/nexa/career.webp" : "https://minio.nexa.net.id/nexa/career.jpeg"

	let mediaCondition;
	if (normalScreen){
		mediaCondition = {"top":"0px", "height":"200px"}
	} else if (bigScreen) {
		mediaCondition = {"top":"180px"}
	} else {
		mediaCondition = {"top":"-80px", "height":"200px"}
	}
	
	return (
		<div>

			<Helmet>
				<title>{`Nexa - ${eTitle}`}</title>
				<meta property="og:title" content={eTitle} ></meta>
				<meta property="og:site_name" content="Nexa"></meta>
				<meta property="og:url" content={window.location.href}></meta>
				<meta property="og:description" content={sAfterBanner_title}></meta>
				<meta name="description" content={sAfterBanner_title}></meta>
				<meta property="og:type" content="product"></meta>
				<meta property="og:image" content={mainBannerPage}></meta>
			</Helmet>
			
			<Banner title={eBanner} desc={sBanner_desc} url_image={mainBannerPage} style={(mediaQuery) ? {"maxWidth":"unset"} : {}} />
			
			<AfterBanner title={sAfterBanner_title} desc={sAfterBanner_desc} styleSection={mediaCondition} />
			{ (result == null ) && <Content storeData={result} storeDataCareer={objCareer} /> }
			{ (result !== null ) && <Content storeData={result} storeDataCareer={objCareer} careerText={careerText} /> }
			{/* { (objCareer == null ) && <Content storeDataCareer={objCareer} /> }
			{ (objCareer !== null ) && <Content storeDataCareer={objCareer} />} */}
			
		</div>
		);
}

export default Career;